<template>
  <div v-if="isLoading" class="spinner-wrapper bg-white">
    <lottie-animation
        :width=300
        :height=300
        path="animation/freta-loading.json"
      />
  </div>
  <div v-else class="orders">
    <div class="container">
      <div class="order-details-wrapper">
        <div class="order-inner-detail row" :style="cssProps">
          <div class="col-trip-info col-lg-12">
            <div class="order-inner-detail-header">
            <h2 class="info-row">Pedido #{{this.order.id}}</h2>
              <div class="row-order-info">
                <span class="order-total" :style="{color: 'black'}">Data do pedido <strong>{{orderDate(this.order.orderDate)}}</strong>  | {{this.order.items.length}} <span><span v-if="order.items.length > 1">Itens</span><span v-else>Item</span></span> | <strong>{{this.order.total | currency}}</strong></span>
              </div>
            </div>
          </div>
          
          <div class=" col-lg-12">
            <order-payment-details :order="order" />
          </div>

          <!-- <div class="col-md-12">
            <b-alert v-if="success" show variant="success" class="m-0 mb-3">Lista enviada com sucesso!</b-alert>

            <div v-for="(item, index) in order.items" :key="index" >
              <order-trip-item @open-modal="openModal" :item="item" :index="index" template="order"></order-trip-item>
            </div>
          </div> -->

          <div class="col-12">
            <order-summary :order="order" />
          </div>

        
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import OrderTripItem from '@/components/my-account/OrderTripItem.vue'
import OrderPaymentDetails from '@/components/general/OrderPaymentDetails.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import OrderSummary from '../components/order/OrderSummary.vue'

export default {
  name: 'Order',
  metaInfo() {
    return {
      titleTemplate: '%s - Detalhes do pedido',
    }
  },
  data () {
    return {
      success: false,
      deleteConfirmation: 0,
      isLoading: true,
      order: null,
      editing_item: null,
      list_passanger_title: '',
      paymentCols: ['vencimento', 'status', 'valor', 'parcela', 'show_details'],
      passenger: {
        id: 0,
        name: '',
        document: '',
        documentType: '',
        documentIssuer: '',
        isChild: ''
      },
      nameState: null,
      passengers: [],
      origin: {},
      destination: {},
      intallments: [],
      details_visible: false,
      personOptions: [
        {
          key: 'yes',
          label: 'Sim'
        },
        {
          key: 'no',
          label: 'Não'
        }
      ],
      documentTypes: [
        { key: 'rg', label: 'RG' }, { key: 'cnh', label: 'CNH' }, { key: 'passaporte', label: 'Passaporte' }, { key: 'certidao', label: 'Certidão' }
      ]
    }
  },
  components: { OrderTripItem, OrderPaymentDetails, LottieAnimation, OrderSummary },
  created () {
    /* eslint-disable no-new */
    new Promise((resolve, reject) => {
      axios.get(process.env.API_URL + 'user/orders/' + this.$route.params.slug).then((r) => {
        this.order = r.data.data
        this.isLoading = false
        this.intallments = this.order.payment.installments
      })
    })
  },
  methods: {
    showAlert (name) {
      return new Promise((resolve, reject) => {
        this.$swal({
          title: 'Espere!',
          text: 'Tem certeza que deseja remover ' + name + ' da lista de passageiros?',
          input: 'checkbox',
          inputValue: this.deleteConfirmation,
          inputPlaceholder:
            'Não pedir confirmação novamente',
          showDenyButton: true,
          confirmButtonText: 'Confirmar',
          denyButtonText: `Cancelar`
        }).then((result) => {
          this.deleteConfirmation = result.value
          resolve(result.isConfirmed)
        })
      })
    },
    cleanList () {
      return new Promise((resolve, reject) => {
        this.$swal({
          title: 'Tem certeza?!',
          text: 'Você está prestes a excluir toda a lista de passageiros',
          showDenyButton: true,
          confirmButtonText: 'Confirmar',
          denyButtonText: `Cancelar`
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.deleteConfirmation = 1
            await this.passengers.forEach((passenger, index) => {
              this.removePassenger(index, true)
              // console.log(index)
            })

            this.passengers = []

            this.$swal('Tudo certo', 'A lista foi excluída!', 'success')
          }
          resolve(result.isConfirmed)
        })
      })
    },
    orderDate (dateCreatedOrder) {
      return this.moment(new Date(dateCreatedOrder)).format('DD/MM/YYYY HH:mm:ss ')
    },
    handleOk (bvModalEvt) {
      const orderItemId = this.order.items[0].id
      axios.post(process.env.API_URL + `services/${orderItemId}/passengers/insert`, { passengers: this.passengers })
        .then((response) => {
          this.passengers = response.data.passengers
          this.success = true
        })
    },
    openModal (passengers) {
      this.loadPassengers()
      this.$refs['passenger-list'].toggle()
    },
    getPaymentMethod (val) {
      switch (val) {
        case 'bank_slip':
          return 'Boleto'
        case 'bank_slip_parcel':
          return 'Boleto Parcelado'
        case 'bank_slip_parcel_2':
          return 'Boleto Parcelado 2x'
        case 'credit_card':
          return 'Cartão de Crédito'
        default:
          break
      }
    },
    filterPaymentStatus (val) {
      switch (val) {
        case 'active':
          return 'Pendente'
      }

      return val
    },
    addPassenger () {
      if (!this.checkFormValidity()) {
        return
      }

      if (this.passengers) {
        this.passengers.push({
          name: this.passenger.name,
          document: this.passenger.document,
          documentType: this.passenger.documentType,
          documentIssuer: this.passenger.documentIssuer,
          isChild: this.passenger.isChild
        })

        const orderItemId = this.order.items[0].id
        axios.post(process.env.API_URL + `services/${orderItemId}/passengers/insert`, { passengers: [{
          id: 0, // Refatorar o backend para inserir um passageiro por vez
          name: this.passenger.name,
          document: this.passenger.document,
          documentType: this.passenger.documentType,
          documentIssuer: this.passenger.documentIssuer,
          isChild: this.passenger.isChild,
          order_item_id: orderItemId
        }] })
          .then((response) => {
            // this.passengers = response.data.passengers
            this.success = true
            this.passenger.document = ''
            this.passenger.name = ''
            this.passenger.documentType = ''
            this.passenger.documentIssuer = ''
            this.passenger.isChild = ''
          })
      }
    },
    async removePassenger (index, cleanAll = false) {
      let passenger = this.passengers[index]
      try {
        let shouldIRemoveThisPassenger = this.deleteConfirmation === 0 ? await this.showAlert(passenger.name) : true
        if (shouldIRemoveThisPassenger) {
          if (passenger !== undefined && passenger.id !== 0) {
            const orderItemId = this.order.items[0].id

            axios.post(process.env.API_URL + `services/${orderItemId}/passenger/${passenger.id}/delete`)
              .then((response) => {
                // this.passengers = response.data.passengers
                if (!cleanAll) {
                  this.success = true
                  this.passengers.splice(index, 1)

                  this.$swal('Tudo certo', 'Passageiro excluído com sucesso!', 'success')
                }
              })
          }
        }
      } catch (error) {
      }

      // let passenger = this.passengers[index]
      // console.log(passenger)

      // this.passengers.splice(index, 1)

      // if (passenger !== undefined && passenger.id !== 0) {
      //   const orderItemId = this.order.items[0].id

      //   axios.post(process.env.API_URL + `services/${orderItemId}/passenger/${passenger.id}/delete`)
      //     .then((response) => {
      //       this.passengers = response.data.passengers
      //       this.success = true
      //       this.passengers.splice(index, 1)
      //     })
      // } else {
      //   this.passengers.splice(index, 1)
      // }
    },
    loadPassengers () {
      const orderItemId = this.order.items[0].id

      axios({
        method: 'GET',
        url: process.env.API_URL + `services/${orderItemId}/passengers/list`
      })
        .then((response) => {
          this.passengers = response.data.passengers
        })
    },
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    }
  },
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    }
  }
}
</script>

<style lang="scss">

  /* Modal */

  .orders {
    background-color: #e8e8e8;
  }

  #passenger-list.modal.fade.show {
    height: 100vh;
    align-items: center;
    justify-content: center!important;
    display: flex!important;
    padding-left: 0!important;
  }

  #passenger-list.modal.fade.show .modal-dialog {
    width: 90vw !important;
    max-width: 1290px!important;
    position: relative;
    margin: 0 auto;
    height: auto;
    padding-top: 0;
    background-color: white!important;
    overflow: auto;
    border-radius: 10px;
    max-height: 85vh;
  }

  #passenger-list.modal.fade.show .modal-dialog label {
    font-size: 12px;
    margin-bottom: 0;
  }


  #passenger-list.modal.fade.show .modal-content .col-md-3, #passenger-list.modal.fade.show .modal-content .col-md-2,  #passenger-list.modal.fade.show .modal-content .col-md-1:not(:last-of-type) {
    padding-right: 0;
  }

  #passenger-list.modal.fade.show .modal-content .form-control {
    border: 1px solid #d1d1d1!important;
    height: auto;
  }

    #passenger-list.modal.fade.show .modal-content {
      min-height: 100%;
      background: white !important;
      height: auto;
      overflow: auto;
    }

    #passenger-list .btn-primary {
      width: 100%;
      margin: 0 15px;
      background: white;
      color: #143381;
      margin-bottom: 20px;
    }

    #passenger-list .btn-success:disabled {
      background: #bebebe;
      border-color: #bebebe;
      cursor: not-allowed;
    }

    #passenger-list .btn-danger {
      background: white;
      color: #e63e36;
      padding: 10px 20px;
    }

    #passenger-list .btn-success {
      color: white;
      background: #143381;
      border-color: #143381;
      padding: 10px 20px;
    }

    #passenger-list footer {
      position: sticky;
      bottom: 0;
      width: 100%;
      background: white;
      margin: 0;
      z-index: 9999;
    }

    #passenger-list .tag {
      border-radius: 100px;

      width: fit-content;
      padding: 1px 20px;
      color: white;
      font-size: 12px;
    }

    #passenger-list .child-tag {
      background-color: #3e6ee7;
    }

    #passenger-list .adult-tag {
      background-color: #1b2f62;
    }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
    opacity: 0;
  }

  .detail-text {
    display: flex;
    justify-content: space-between;
  }

  .detail-title {
    font-size: 12px;
    font-weight: bold;
  }

  .order-inner-detail {
    padding: 3rem 0;

    .order-inner-detail-header  {
      background-color: var(--default-color);
      padding: 30px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      * {
        color: white!important;
      }
    }

    .vehicle-image{
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 8px;
      height: 200px;

      @media (max-width: 576px) {
        width: 100%;
        height: auto;
      }
    }
  }

  .order-actions {
    gap: 1rem;
    flex-direction: row;
  }

  @media (max-width: 424px) {
    .order-actions {
      flex-direction: column;
    }

    .btn-order-item-action {
        width: 100%;
    }

  }

  .line {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .btn-order-item-action {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    margin: 0;
    padding: 13px 17px;
    outline: 0;
    background: none;
    border-color: #1c1f40 !important;
    font-family: Montserrat,Gotham,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    line-height: 1.4;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-color: #1c1f40;

  }

  .icon-action{
      margin-right: 8px;
      font-size: 1.2rem;
    }

  .payment-status {
    text-align: center;
  }

  @media (min-width: 576px) {

    .modal-dialog {
        max-width: 690px !important;
        margin: 1.75rem auto;
    }
  }

  .payment-status {
      background: #fcc736;
      color: #fff;
      min-height: 24px;
      padding: 4px 12px;
      border-radius: 60px;
  }

  .orders {

    .label {
      font-weight: bold;
    }

    .col-trip-info {
      .info-row {
        text-align: left;
        color: #000;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      table {
        .additional {
          th,
          td {
            color: #67acbc;
          }
        }

        th {
          text-align: left;
        }

        td {
          text-align: right;
        }
      }
    }

    .col-send-receipt  {
      a {
        text-decoration: none;
      }

      text-align: center;

      span {
        display: block;
        color: #000;
        font-size: 18px;
        line-height: 1;
        margin-bottom: 15px;
      }

      svg {
        font-size: 36px;
        color: #67acbc;
      }
    }

    .col-status {
      .label {
        color: #000;
        font-size: 18px;
      }

      span {
        font-size: 14px;
        display: block;
      }
    }

    .col-actions {
      .btn-send-list {
        background-color: #4ca92b;
        color: #fff;
        border-radius: 100px;
        margin-bottom: 20px;
        font-size: 14px;
      }

      .details {
        text-transform: uppercase;
      }
    }

    .order {
      margin: 15px;

      .order-inner-detail {
        display: flex;
        flex-wrap: wrap;
        // box-shadow: 0 16px 64px -16px rgba(46, 55, 77, 0.2);

      }
    }
  }

  .row-payment-info-header {
    text-align: center;
    margin-left: 0;
    margin-right: 0;
  }

  .payment-info {
    text-align: center;
    margin-bottom: 10px;
    background-color: #e8e8e8;
    border-radius: 5px;

    .payment-info-row {
      padding-top: 15px;
      padding-bottom: 15px;
      margin-left: 0;
      margin-right: 0;
    }

    .collapse {
      margin-top: 0;

      .card {
        border: none;
        background: transparent;
        padding: 0 10px;
        margin-bottom: 0;

        .card-body {
          .payment-details {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }

  .order-resume-row {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem;
  }

  .row-order-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
